.custom-toast {
  background-color: rgba(236, 36, 36, 0.949) !important;
  color: white !important;
}

.custom-toast svg {
  fill: white !important;
}

.custom-progress {
  background-color: white !important; 
}
